<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-chart-bar-stacked"    
    title="Lagerorte die von WawiPlus optimiert werden sollen"
      class="mb-5 pb-5"
      >
      <v-row>
        <v-col cols="1"></v-col>        
        <v-col cols="3">
        <v-sheet v-for="(item, index) in lagerorte"
          :key="index">
                 <v-switch
                 inset
              v-model="item.optimierung"
              :label="` ${item.name}`"
            ></v-switch>
        </v-sheet>
      </v-col> 
      </v-row>
      </material-card>
</v-container>
</template>

<script>
  import axios from "axios";
  
  export default {
    name: 'EinstellungLagerortView',
    components: {
       
  },
  data() {
    return {
      lagerorte: []
    }
  },
  methods: {
    
  },
  mounted() {
    
  },
  async created() {

    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/apotheken/lagerort/" + apoId);    
      
      if (response) {     

          this.lagerorte = response.data;  

      }        
    } catch (e) {
      this.errors.push(e);
    }
  }
}

</script>