<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-cash-register"    
    title="Verteilung Verkäufe"
      class="mb-5 pb-5"
      >      
      <v-row>
        <v-col cols="12">
          <p class="text-h4 text-center header--text">Verteilung abgegebene PZN nach Anzahl Abgaben in den letzte 12 Monaten</p>
          <v-data-table                    
            :headers="headersTable"
            :items="tableAnzahl"
            :items-per-page="10"
            class="ml-5 mr-5 mb-5"    
            hide-default-footer
  >
  <template v-for="h in headersTable" v-slot:[`header.${h.value}`]="{ headersTable }">
              <v-tooltip bottom :key="h.text">
                <template v-slot:activator="{ on }">
                  <span v-html="h.text.replace('#', '<br />')" v-on="on"></span>
                </template>
                <span>{{h.text}}</span>
              </v-tooltip>
            </template>         
</v-data-table>
        </v-col>
      </v-row> 
      </material-card>
      <material-card>      
      <v-row>
        <v-col cols="12">          
          <p class="text-h4 text-center header--text">Verteilung abgegebene Packungen nach Summe je PZN in den letzten 12 Monaten</p>
          <v-data-table          
            :headers="headersTable2"
            :items="tableSumme"
            :items-per-page="10"
            class="ml-5 mr-5 mb-5"    
            hide-default-footer
  >
  <template v-for="h in headersTable2" v-slot:[`header.${h.value}`]="{ headersTable2 }">
              <v-tooltip bottom :key="h.text">
                <template v-slot:activator="{ on }">
                  <span v-html="h.text.replace('#', '<br />')" v-on="on"></span>
                </template>
                <span>{{h.text}}</span>
              </v-tooltip>
            </template>         
</v-data-table>
        </v-col>
      </v-row> 
  </material-card>

</v-container>
</template>

<script>
  import axios from "axios";
  
  export default {
    name: 'VerkaufVerteilungView',
    components: {
       
  },
  data() {
    return {
      headersTable: [
        {
          text: "Abgabeart",
          sortable: false,
          value: 'abgaberegel',
          width: "12%"
        },
        {
          text: "PZN#Gesamt",
          sortable: false,
          value: 'wertGesamt',
          align: 'right',
          width: "8%"
        },
        {
          text: "Eine#Abgabe",
          sortable: false,
          value: 'wert1',
          align: 'right',
          width: "8%"
        },
        {
          text: "Zwei#Abgaben",
          sortable: false,
          value: 'wert2',
          align: 'right',
          width: "8%"
        },
        {
          text: "Drei#Abgaben",
          sortable: false,
          value: 'wert3',
          align: 'right',
          width: "8%"
        },
        {
          text: "Vier#Abgaben",
          sortable: false,
          value: 'wert4',
          align: 'right',
          width: "8%"
        },
        {
          text: "Fünf#Abgaben",
          sortable: false,
          value: 'wert5',
          align: 'right',
          width: "8%"
        },
        {
          text: "Sechs#Abgaben",
          sortable: false,
          value: 'wert6',
          align: 'right',
          width: "8%"
        },
        {
          text: "Sieben#Abgaben",
          sortable: false,
          value: 'wert7',
          align: 'right',
          width: "8%"
        },
        {
          text: "Acht#Abgaben",
          sortable: false,
          value: 'wert8',
          align: 'right',
          width: "8%"
        },
        {
          text: "Neun#Abgaben",
          sortable: false,
          value: 'wert9',
          align: 'right',
          width: "8%"
        },
        {
          text: ">=10#Abgaben",
          sortable: false,
          value: 'wert10',
          align: 'right',
          width: "8%"
        }
      ],headersTable2: [
        {
          text: "Abgabeart",
          sortable: false,
          value: 'abgaberegel',
          width: "12%"
        },
        {
          text: "Packungen#Gesamt",
          sortable: false,
          value: 'wertGesamt',
          align: 'right',
          width: "8%"
        },
        {
          text: "PZN mit#einer Packung",
          sortable: false,
          value: 'wert1',
          align: 'right',
          width: "8%"
        },
        {
          text: "PZN mit#zwei Packungen",
          sortable: false,
          value: 'wert2',
          align: 'right',
          width: "8%"
        },
        {
          text: "PZN mit#drei Packungen",
          sortable: false,
          value: 'wert3',
          align: 'right',
          width: "8%"
        },
        {
          text: "PZN mit#vier Packungen",
          sortable: false,
          value: 'wert4',
          align: 'right',
          width: "8%"
        },
        {
          text: "PZN mit#fünf Packungen",
          sortable: false,
          value: 'wert5',
          align: 'right',
          width: "8%"
        },
        {
          text: "PZN mit#sechs Packungen",
          sortable: false,
          value: 'wert6',
          align: 'right',
          width: "8%"
        },
        {
          text: "PZN mit#sieben Packungen",
          sortable: false,
          value: 'wert7',
          align: 'right',
          width: "8%"
        },
        {
          text: "PZN mit#acht Packungen",
          sortable: false,
          value: 'wert8',
          align: 'right',
          width: "8%"
        },
        {
          text: "PZN mit#neun Packungen",
          sortable: false,
          value: 'wert9',
          align: 'right',
          width: "8%"
        },
        {
          text: "PZN mit#>=10 Packungen",
          sortable: false,
          value: 'wert10',
          align: 'right',
          width: "8%"
        }
      ],
      tableAnzahl: [],
      tableSumme: []
    }
  },
  methods: {
    toFixedIfNecessary( value, dp ){
      if (value >= 1000) {
        return value.toLocaleString();
      }
      else {
        return +parseFloat(value).toFixed( dp );
      }
    }
  },
  mounted() {
    
  },
  async created() {

    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/report/verkaufVerteilung/" + apoId);    
      
      if (response) {  
          if(response.data.tabelleAnzahl)
          {
            response.data.tabelleAnzahl.forEach(element => {
              this.tableAnzahl.push(
                {
                  abgaberegelId: element.abgaberegelId,
                  abgaberegel: element.abgaberegel,
                  wertGesamt: this.toFixedIfNecessary(element.wertGesamt),
                  wert1: this.toFixedIfNecessary(element.wert1),
                  wert2: this.toFixedIfNecessary(element.wert2),
                  wert3: this.toFixedIfNecessary(element.wert3),
                  wert4: this.toFixedIfNecessary(element.wert4),
                  wert5: this.toFixedIfNecessary(element.wert5),
                  wert6: this.toFixedIfNecessary(element.wert6),
                  wert7: this.toFixedIfNecessary(element.wert7),
                  wert8: this.toFixedIfNecessary(element.wert8),
                  wert9: this.toFixedIfNecessary(element.wert9),
                  wert10: this.toFixedIfNecessary(element.wert10),
                }
              )  
            });            
          }
          if(response.data.tabelleSumme)
          {
            response.data.tabelleSumme.forEach(element => {
              this.tableSumme.push(
                {
                  abgaberegelId: element.abgaberegelId,
                  abgaberegel: element.abgaberegel,
                  wertGesamt: this.toFixedIfNecessary(element.wertGesamt),
                  wert1: this.toFixedIfNecessary(element.wert1),
                  wert2: this.toFixedIfNecessary(element.wert2),
                  wert3: this.toFixedIfNecessary(element.wert3),
                  wert4: this.toFixedIfNecessary(element.wert4),
                  wert5: this.toFixedIfNecessary(element.wert5),
                  wert6: this.toFixedIfNecessary(element.wert6),
                  wert7: this.toFixedIfNecessary(element.wert7),
                  wert8: this.toFixedIfNecessary(element.wert8),
                  wert9: this.toFixedIfNecessary(element.wert9),
                  wert10: this.toFixedIfNecessary(element.wert10),
                }
              )  
            });            
          }
      }        
    } catch (e) {
      console.writeln(e);
    }
  }
}

</script>