<template>
<v-container>
  <v-form v-model="isFormValid">
  <material-card    
    color="primary" 
    icon="mdi-lock-reset"    
    title="Passwort zurücksetzen"
      class="mb-5 pb-5"
      >
      <v-row>
        <v-col cols="3" class="ml-5">          
          <v-text-field
            v-model="emailadresse"            
            type="text"
            label="E-Mail Adresse"
            :rules="[rules.required, rules.emailRule]"
            required
          ></v-text-field>
        </v-col>        
      </v-row>    
      <v-row>
        <v-col cols="12" class="ml-5">     
          <v-btn color="primary" v-on:click="resetPassword" :disabled="!isFormValid || passwordreset" :loading="passwordresetloading">Passwort zurücksetzen </v-btn>
          <p class="text-h4 mt-5" v-if="passwordreset">Es wurden weitere Informationen zum Passwort zurücksetzen an Ihre E-Mail Adresse geschickt.</p>
        </v-col>
      </v-row>     
  </material-card>
</v-form>
</v-container>
</template>

<script>
  import axios from "axios";

  export default {
    name: 'NewPassword',
    components: {
       
  },
  data() {
    return {
      isFormValid: false,
      emailadresse: "",
      passwordresetloading: false,
      passwordreset: false,
        rules: {
          required: value => !!value || 'Erforderlich',
          emailRule: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Bitte gültige E-Mail Adresse eingeben',      
        },  
    }    
  },
  methods: {    
    async resetPassword() {
      this.passwordresetloading = true;
      const apiUrl = process.env.VUE_APP_API_URL;

      try {

        var dto = {
          email: this.emailadresse
        }

        await axios.post(apiUrl + "/administration/resetpassword", dto);

        this.passwordreset = true;
      }
      catch (e) {
        console.write(e)
      }

      this.passwordresetloading = false;

    }
  },
  async created() {
    this.$store.commit('setPageTitle', "Passwort zurücksetzen");
  }
}

</script>