<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <material-card icon="mdi-filter" icon-small title="Filter" color="primary" class="mb-10">
      <v-row no-gutters>
        <v-col cols="2" sm="4" md="2" align-self="end">
          <v-text-field
            class="pa-4"
            v-model="filter.name"
            label="Name"
          ></v-text-field>
        </v-col>
        <v-col cols="2" sm="4" md="2" align-self="end">
          <v-text-field
            class="pa-4"
            v-model="filter.idf"
            label="IDF-Nummer"
          ></v-text-field>
        </v-col>
        <v-col cols="2" sm="4" md="2" align-self="end">
          <v-text-field
            class="pa-4"
            v-model="filter.kundennummer"
            label="Kundennummer"
          ></v-text-field>
        </v-col>
        <v-col cols="2" sm="4" md="2" align-self="end">
          <v-text-field
            class="pa-4"
            v-model="filter.ort"
            label="Ort"
          ></v-text-field>
        </v-col>
        </v-row>
</material-card>
<material-card
      icon="mdi-mortar-pestle-plus"
      icon-small
      title="Apotheken"
      color="primary"
    >
    <v-data-table
        :headers="headers"
        :items="apothekenFiltered"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [50, 100, 150, 200]
        }"
        class="pl-5 pr-5"
      >
        <template v-slot:item.name="{ item }">
            {{ item.name }}
        </template>
        <template v-slot:item.ort="{ item }">
            {{ item.ort }}
        </template>
        <template v-slot:item.idf="{ item }">
            {{ item.idf }}
        </template>
        <template v-slot:item.kundennummer="{ item }">
            {{ item.kundennummer }}
        </template>
        <template v-slot:item.edit="{ item }">
          <v-icon @click="openItem(item)" class="mr-4">
            mdi-cog
          </v-icon>
          <v-icon @click="setSelectedApotheke(item)" class="mr-4">
            mdi-eye-arrow-right
          </v-icon>
        </template>        
      </v-data-table>
    </material-card>
    <apotheke-detail-dialog ref="apothekeDetailEditDialog" />
  </v-container>
</template>

<script>

import ApothekeDetailDialog from "../components/ApothekeDetailDialog.vue";
import axios from "axios";

export default {
    name: 'PharmaciesOverview',
    components: {
    ApothekeDetailDialog
  },
  props: {
    
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Ort",
          align: "start",
          sortable: true,
          value: "ort",
        },
        {
          text: "IDF-Nummer",
          align: "start",
          sortable: true,
          value: "idf",
        },
        {
          text: "Kundennummer",
          align: "start",
          sortable: true,
          value: "kundennummer",
        },
        { text: "", value: "edit", sortable: false, align: "end" },
      ],
      apotheken: [],
      apothekenStore: [],
      apothekenFiltered: [],      
      filter: {
        name: null,
        idf: null,
        kundennummer: null,
        ort: null
      },
    }
  },
  methods: {
    openItem(item) {
      this.apothekenStore = Object.assign({}, this.apotheke);
      this.$refs.apothekeDetailEditDialog.showDialog(item, this.filter);
    },
    setSelectedApotheke(item) {      
      this.$store.commit('setSelectedApotheke', item);     
        const datum = new Date(Date.parse(item.waweeAktivSeit)); 
      this.$store.commit('setPageTitle', item.name + ' - (WawiPlus aktiv seit ' + datum.toLocaleDateString('de-DE') + ')');
      
      this.$router.push("/dashboard/" + item.id);
    },
  },
  computed: {
        
  },
  watch: {
    filter: {
      handler(newData) {
        this.apothekenFiltered = this.apotheken;

        if (newData.name) {          
          this.apothekenFiltered = this.apothekenFiltered.filter(function (el) {
            if (el.name) {
              return el.name.toLowerCase().includes(newData.name.toLowerCase());
            }
            else {
              return false;
            }
          });
        }
        
        if (newData.ort) {          
          this.apothekenFiltered = this.apothekenFiltered.filter(function (el) {
            if (el.ort) {
              return el.ort.toLowerCase().includes(newData.ort.toLowerCase());
            }
            else {
              return false;
            }
          });
        }
        
        if (newData.idf) {          
          this.apothekenFiltered = this.apothekenFiltered.filter(function (el) {
            if (el.idf) {
              return el.idf.toString().includes(newData.idf.toString());
            }
            else {
              return false;
            }
          });
        }
        
        if (newData.kundennummer) {          
          this.apothekenFiltered = this.apothekenFiltered.filter(function (el) {
            if (el.kundennummer) {
              return el.kundennummer.toString().includes(newData.kundennummer.toString());
            }
            else {
              return false;
            }
          });
        }
      },      
      deep: true,
    }
  },
  async created() {
    this.$store.commit('setPageTitle', "Apotheke auswählen");

    try {      
      const apiUrl = process.env.VUE_APP_API_URL;
      const response = await axios.get(apiUrl + "/apotheken/liste");  

      if(response) {
        this.apotheken = response.data;
        this.apothekenFiltered = this.apotheken;
      }

    } catch (e) {
      this.errors.push(e);
    }
  },
}
</script>
