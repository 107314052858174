<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-playlist-check"    
    title="Eiserne Bestände"
      class="mb-5 pb-5"
      >      
      <v-row>
        <v-col cols="12">          
          <v-data-table                    
            :headers="headersTable"
            :items="tableEisernerBestand"
            items-per-page="15"
            class="ml-5 mr-5 mb-5"
  >
  <template v-for="h in headersTable" v-slot:[`header.${h.value}`]="{ headersTable }">
              <v-tooltip bottom :key="h.text">
                <template v-slot:activator="{ on }">
                  <span v-html="h.text.replace('#', '<br />')" v-on="on"></span>
                </template>
                <span>{{h.text}}</span>
              </v-tooltip>
            </template>         
</v-data-table>
        </v-col>
      </v-row>
  </material-card>

</v-container>
</template>

<script>
  import axios from "axios";
  
  export default {
    name: 'EiserneBestaendeView',
    components: {
       
  },
  data() {
    return {
      headersTable: [
        {
          text: "PZN",
          sortable: true,
          value: 'pzn',
          width: "8%"
        },
        {
          text: "Name",
          sortable: true,
          value: 'name',
          width: "24%"
        },
        {
          text: "Lagerort",
          sortable: true,
          value: 'lagerort',
          align: 'left',
          width: "12%"
        },
        {
          text: "Eiserner Bestand",
          sortable: true,
          value: 'eisernerBestand',
          align: 'right',
          width: "8%"
        },
        {
          text: "Wawi Min",
          sortable: true,
          value: 'wawiMin',
          align: 'right',
          width: "8%"
        },
      ],
      tableEisernerBestand: [],
    }
  },
  methods: {
    toFixedIfNecessary( value, dp ){
      if (value >= 1000) {
        return value.toLocaleString();
      }
      else {
        return +parseFloat(value).toFixed( dp );
      }
    }
  },
  mounted() {
    
  },
  async created() {

    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/apotheken/eisernebestaende/" + apoId);    
      
      if (response) {  
          if(response.data)
          {
            response.data.forEach(element => {
              this.tableEisernerBestand.push(
                {
                  pzn: element.pzn,
                  name: element.name,
                  lagerort: element.lagerort,
                  eisernerBestand: this.toFixedIfNecessary(element.eisernerBestand),
                  wawiMin: this.toFixedIfNecessary(element.wawiMin)
                }
              )  
            });            
          }
      }
    } catch (e) {
      console.writeln(e);
    }
  }
}

</script>