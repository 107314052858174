<template>
<v-container>
  <v-row>
  <v-col cols="4">
  <material-card
    color="primary"
    icon="mdi-chart-bar-stacked"  
    icon-small
    :title="aktuellerLagerwertText"
    class="mb-10"
      >
      <v-card-text class="text-h2 primary--text" align="center"
      justify="center">
      {{ toFixedIfNecessary(this.lagerwertAktuell) }} €
      </v-card-text>
  </material-card>
</v-col>
  <v-col cols="4">
  <material-card
    color="primary"
    icon="mdi-account-check-outline"  
    :title="aktuelleLieferfaehigkeitText" 
    icon-small
    class="mb-10"
      >
      <v-card-text class="text-h2 primary--text" align="center"
      justify="center">
      {{ this.lieferfaehigkeitGesamtAktuell.toFixed(nachkommastellen) }} %
      </v-card-text>
  </material-card>
</v-col>
  <v-col cols="4">
  <material-card
    color="primary"
    icon="mdi-account-check-outline"  
    :title="aktuelleLieferfaehigkeitRxText" 
    icon-small
    class="mb-10"
      >
      <v-card-text class="text-h2 primary--text" align="center"
      justify="center">
      {{ this.lieferfaehigkeitRxAktuell.toFixed(nachkommastellen) }} %
      </v-card-text>
  </material-card>
</v-col>
</v-row>
  <material-card
    v-for="(item, index) in items"
          :key="index"
    color="primary" 
    :icon="item.icon"    
    :title="item.title"    
      class="mb-15 pb-10"
      >
      <v-row>
  <v-col cols="2">  
      
    </v-col>    
    <v-col cols="2">  
      <div class="text-h6">
        {{ monat }}
      </div>      
    </v-col>    
    <v-col cols="1">  
      <div class="text-h6">
        {{ vormonat }}
      </div>      
    </v-col>
    <v-col cols="3">  
      <div class="text-h6">
        Entwicklung im Vergleich zum {{ vormonat }}
      </div>      
    </v-col>
    <v-col cols="1">  
      <div class="text-h6">
        {{ vorjahr }}
      </div>      
    </v-col>
    <v-col cols="3">  
      <div class="text-h6">
        Entwicklung im Vergleich zum {{ vorjahr }}
      </div>      
    </v-col>
  </v-row>    

<v-row 
    v-for="(zeile, index) in item.items"
          :key="index"
      align="center"
      justify="center"
  >
    <v-col cols="2">  
      <div class="text-h4 pl-4">
        {{ zeile.name }}
      </div>      
    </v-col> 
    <v-col cols="2">  
      <div class="font-weight-black">
        {{ toFixedIfNecessary(zeile.aktuell.wert,nachkommastellen) }}{{ zeile.aktuell.einheit }}
      </div>      
    </v-col>  
    <v-col cols="1">        
        {{ toFixedIfNecessary(zeile.vormonat.wert, nachkommastellen) }}{{ zeile.vormonat.einheit }}      
    </v-col> 
    <v-col cols="1">  
      {{ zeile.vormonatEntwicklung.wert.toFixed(nachkommastellen) }}{{ zeile.vormonatEntwicklung.einheit }}
    </v-col>
    <v-col cols="2">  
      <v-icon size="35" :color="zeile.vormonatEntwicklung.trendColor">      
            mdi-arrow-up-bold-circle-outline
            mdi-rotate-{{ zeile.vormonatEntwicklung.trend }}
         </v-icon>
    </v-col>   
    <v-col cols="1">        
        {{ toFixedIfNecessary(zeile.vorjahr.wert, nachkommastellen) }}{{ zeile.vorjahr.einheit }}      
    </v-col>    
    <v-col cols="1">  
      {{ zeile.vorjahrEntwicklung.wert.toFixed(nachkommastellen) }}{{ zeile.vorjahrEntwicklung.einheit }}
    </v-col>
      <v-col cols="2">  
      <v-icon size="35" :color="zeile.vorjahrEntwicklung.trendColor">      
            mdi-arrow-up-bold-circle-outline
            mdi-rotate-{{ zeile.vorjahrEntwicklung.trend }}
          </v-icon>
    </v-col> 
  </v-row>    

  </material-card>

</v-container>
</template>

<script>  
  import axios from "axios";

  export default {
    name: 'DashboardView',
    components: {
    
  },
  data() {
    return {
      items: null,
      monatText: '',
      monat: 'Monat',
      vormonat: 'Vormonat',
      vorjahr: 'Vorjahr',
      lagerwertAktuell: 0,
      lieferfaehigkeitGesamtAktuell: 0,
      lieferfaehigkeitRxAktuell: 0,
      chartData: {
        name: "",
        labels: [
        
        ],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          },
          {
            label: '',
            backgroundColor: '',
            data: []
          },
        ]
      },
      chartDataApo: {
        name: "",
        labels: [
        
        ],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          },
          {
            label: '',
            backgroundColor: '',
            data: []
          },
        ]
      },
      chartDataGesamt: {
        name: "",
        labels: [
        
        ],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          },
          {
            label: '',
            backgroundColor: '',
            data: []
          },
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      nachkommastellen: 2,
    }
  },
  methods: {
    toFixedIfNecessary( value, dp ){
      if (value >= 1000) {
        return value.toLocaleString();
      }
      else {
        return +parseFloat(value).toFixed( dp );
      }
    }
  },
  computed: {
      aktuellerLagerwertText() {
        return "Aktueller Lagerwert (" + this.monatText + ")";
      },
      aktuelleLieferfaehigkeitText() {
        return "Aktuelle Lieferfähigkeit gesamt (" + this.monatText + ")";
      },
      aktuelleLieferfaehigkeitRxText() {
        return "Aktuelle Lieferfähigkeit RX (" + this.monatText + ")";
      }
  },
  async created() {

    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/apotheken/liste");      
      
      if (apoId && response) {
          response.data.every(element => {
            if(element.id === apoId) {
              this.$store.commit('setApotheken', response.data);
              this.$store.commit('setSelectedApotheke', element);            
              
              return false;
            }
            else {
              return true;
            }
          });

          const responseApo = await axios.get(apiUrl + "/report/uebersicht/" + apoId);
          this.items = responseApo.data.tables;
          this.monat = responseApo.data.aktuellerMonat;
          this.monatText = responseApo.data.aktuellerMonatText;
          this.vormonat = responseApo.data.vormonat;
          this.vorjahr = responseApo.data.vorjahr;
          this.lagerwertAktuell = responseApo.data.lagerwertAktuell;
          this.lieferfaehigkeitGesamtAktuell = responseApo.data.lieferfaehigkeitGesamtAktuell;
          this.lieferfaehigkeitRxAktuell = responseApo.data.lieferfaehigkeitRxAktuell;

          this.$eventHub.$emit('apothekeSelected');
      }
      else {
        if(response && response.data[0]) {
            this.$store.commit('setApotheken', response.data);
            this.$store.commit('setSelectedApotheke', response.data[0]);            
            
            this.$router.push("/dashboard/" + response.data[0].id);
        }
      }
        
    } catch (e) {
      this.errors.push(e);
    }
  }
}

</script>

<stlyle scoped>
  .toggleUpDown.rotate {
    transform: rotate(180deg);
}
</stlyle>
