<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-download"    
    title="Download"
      class="mb-5 pb-5"
      >      
      <v-row>
        <v-col cols="12">          
          <v-data-table          
            :headers="headersTable"
            :items="tableValues"
            :items-per-page="10"
            class="ml-5 mr-5 mb-5"    
            hide-default-footer
          >
          <template v-slot:item.name="{ item }">
          <span>{{ item.name }}</span>
        </template>
        <template v-slot:item.download="{ item }">
          <v-icon v-if="item.deletable" @click="deleteItemQuery(item)">
            mdi-delete
          </v-icon>
          <v-icon class="ml-3" @click="downloadItem(item)">
            mdi-download
          </v-icon>
        </template>
        </v-data-table>
        </v-col>
      </v-row>
  </material-card>

</v-container>
</template>

<script>
  import axios from "axios";
  
  export default {
    name: 'Download',
    components: {
       
  },
  data() {
    return {
      loginWait: false,
        headersTable: [
          {
          text: "Bezeichnung",
          sortable: true,
          value: 'bezeichnung',
        },
        { text: "", value: "download", sortable: false, align: "end" },
      ],
      tableValues: [        
        {
          id: 2,
          bezeichnung: "Besorger",
          download: true
        },
      ],
    }
  },
  methods: {    
    async downloadItem(item) {
      if (item.id === 1) {
        await this.downloadArtikelliste("Artikelliste");
      }
      if (item.id === 2) {
        await this.downloadArtikelliste("Besorgerliste");
      }
      if (item.id === 3) {
        await this.downloadArtikelliste("Startunterlagen");
      }

    },
    async downloadArtikelliste(controllername) {
      const apiUrl = process.env.VUE_APP_API_URL;

      var apotheke = this.$store.state.selectedApotheke;

      var filename = apotheke.idf + "_" + controllername +"_" + this.formatNow() + ".xlsx";

      const config = { responseType: "blob" };
      await axios.get(apiUrl + "/apotheken/" + controllername.toLowerCase() + "/" + apotheke.id, config).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;

        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    formatNow() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }
  },
  computed: {
   
  },
  mounted() {
    
  },
  async created() {
    
  }
}

</script>