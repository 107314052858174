<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-account-cancel-outline"    
    title="Verteilung Besorger (Betrachtungszeitraum: letzte 30 Tage)"
      class="mb-5 pb-5"
      >
      <v-row>
        <v-col cols="12" class="ml-5">          
          <GChart :type="type" :data="data" :options="options" :settings="settings" />
        </v-col>               
      </v-row>  
  </material-card>

</v-container>
</template>

<script>
  import axios from "axios";
  import { GChart } from 'vue-google-charts/legacy';

  export default {
    name: 'VerteilungBesorger',
    components: {
      GChart
  },
  data() {
    return {
      type: 'OrgChart',
      data:  null,
      options: {
          width: 800,
          height: 600,
          allowHtml: true,
          size: 'large',
        },
      settings: {
        packages: ['orgchart'],
      },
    }
  },
  computed: {    
  },
  methods: {    
    
  },  
  async created() {

    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/report/besorgerverteilung/" + apoId);    
      
      if (response) {     
        
        this.data = [
              [
                '',
                '',
                '',
              ],
              [
                {
                  v: 'Verkaufsmenge',
                  f: 'Verkaufsmenge<div style="font-weight: bold;">' + response.data.wert + '</div>',
                },
                '',
                '',
              ],
              [
                {
                  v: 'Besorger',
                  f: 'Besorger<div style="font-weight: bold;">' + response.data.besorger.wert + '</div>',
                },
                'Verkaufsmenge',
                '',
              ],
              [
                {
                  v: 'Lagerartikel',
                  f: 'Lagerartikel<div style="font-weight: bold;">' + response.data.besorger.lagerartikel.wert + '</div>',
                },
                'Besorger',
                '',
              ],
              [
                {
                  v: 'Nichtlagerartikel',
                  f: 'Nichtlagerartikel<div style="font-weight: bold;">' + response.data.besorger.nichtLagerartikel.wert + '</div>',
                },
                'Besorger',
                '',
              ],
              [
                {
                  v: 'Direktabgaben',
                  f: 'Direktabgaben<div style="font-weight: bold;">' + response.data.direktabgaben.wert + '</div>',
                },
                'Verkaufsmenge',
                '',
              ],
              [
                {
                  v: 'RX1',
                  f: 'RX<div style="font-weight: bold;">' + response.data.besorger.lagerartikel.rx + '</div>',
                },
                'Lagerartikel',
                '',
              ],
              [
                {
                  v: 'OTC1',
                  f: 'OTC<div style="font-weight: bold;">' + response.data.besorger.lagerartikel.otc + '</div>',
                },
                'Lagerartikel',
                '',
              ],
              [
                {
                  v: 'FW1',
                  f: 'FW<div style="font-weight: bold;">' + response.data.besorger.lagerartikel.fw + '</div>',
                },
                'Lagerartikel',
                '',
              ],
              [
                {
                  v: 'RX2',
                  f: 'RX<div style="font-weight: bold;">' + response.data.besorger.nichtLagerartikel.rx + '</div>',
                },
                'Nichtlagerartikel',
                '',
              ],
              [
                {
                  v: 'OTC2',
                  f: 'OTC<div style="font-weight: bold;">' + response.data.besorger.nichtLagerartikel.otc + '</div>',
                },
                'Nichtlagerartikel',
                '',
              ],
              [
                {
                  v: 'FW2',
                  f: 'FW<div style="font-weight: bold;">' + response.data.besorger.nichtLagerartikel.fw + '</div>',
                },
                'Nichtlagerartikel',
                '',
              ],
              [
                {
                  v: 'RX3',
                  f: 'RX<div style="font-weight: bold;">' + response.data.direktabgaben.rx + '</div>',
                },
                'Direktabgaben',
                '',
              ],
              [
                {
                  v: 'OTC3',
                  f: 'OTC<div style="font-weight: bold;">' + response.data.direktabgaben.otc + '</div>',
                },
                'Direktabgaben',
                '',
              ],
              [
                {
                  v: 'FW3',
                  f: 'FW<div style="font-weight: bold;">' + response.data.direktabgaben.fw + '</div>',
                },
                'Direktabgaben',
                '',
              ],
            ];
      }        
    } catch (e) {
      this.errors.push(e);
    }
  }
}

</script>