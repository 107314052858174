<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ viewModel.name }}</v-toolbar-title>
            <v-spacer v-if="isAdmin"></v-spacer>
            <v-toolbar-items v-if="isAdmin">
              <v-btn dark text @click="save()">Speichern</v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-tabs v-model="tab" background-color="primary" dark grow>
            <v-tab v-for="item in tabItems" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item :key="tabItems[0]">
              <apotheke-general-component
                v-model="apothekeDetails"
                :filter="filter"
                :readOnly="!isAdmin"
              />
            </v-tab-item>
            <v-tab-item v-if="isAdmin" :key="tabItems[1]">
              <apotheke-downloads-component
                v-model="apothekeDetails"
                :filter="filter"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import ApothekeGeneralComponent from "../components/ApothekeAllgemein.vue";
import ApothekeDownloadsComponent from "./ApothekeDownloads.vue";
import axios from "axios";
import { mdiShareVariant } from "@mdi/js";
import { sync } from "vuex-pathify";

export default {
  name: "DatabaseDetailDialog",
  components: {
    ApothekeGeneralComponent,
    ApothekeDownloadsComponent,
  },

  data() {
    return {
      dialog: false,
      tab: null,
      tabItems: ["Übersicht", "Downloads"],
      apothekeDetails: {
        id: null,
        name: "",
        nameZusatz: "",
        idf: "",
        betreuer: "",
        strasse: "",
        plz: "",
        ort: "",
        kundennummer: null,
        telefon: "",
        kontaktEmail: "",
        vermittler: "",
        mandatierungVorhanden: false, 
        mandatierungVerschickt: false,
        onlineSeit: null,
        gekuendigt: false,
        gekuendigtZum: null,
        sepaMandat: false,
        rechungsbetrag: null,
        nameZahlungsdienstleisters: null,
        iban: null,
        bic: null,
      },
      viewModel: {
        id: null,
        name: "",
        nameZusatz: "",
        idf: "",
        betreuer: "",
        strasse: "",
        plz: "",
        ort: "",
        kundennummer: null,
        telefon: "",
        kontaktEmail: "",
        vermittler: "",
        mandatierungVorhanden: false, 
        mandatierungVerschickt: false,
        onlineSeit: null,
        gekuendigt: false,
        gekuendigtZum: null,
        sepaMandat: false,
        rechungsbetrag: null,
        nameZahlungsdienstleisters: null,
        iban: null,
        bic: null,
      },
      viewModelInital: {},
      filter: null,
      icons: {
        mdiShareVariant,
      },
    };
  },

  methods: {
    async showDialog(item, filter) {
      this.viewModel = item;
      const cloneDeep = require("lodash/cloneDeep");
      this.viewModelInital = cloneDeep(this.viewModel);
      this.tab = null;

      try {
        const apiUrl = process.env.VUE_APP_API_URL;
        let res = await axios.get(apiUrl + "/administration/apothekeDetails/" + this.viewModel.id);
        this.apothekeDetails = res.data;
        
      } catch (err) {        
        console.log(err.response);        
      }

      this.dialog = true;
      this.filter = filter;

    },
    close() {
      Object.assign(this.viewModel, this.viewModelInital);
      this.dialog = false;
    },
    openLink() {
      window.open(this.viewModel.hyperlink, "_blank").focus();
    },
    async save() {
      this.dialog = false;

      try {
        const apiUrl = process.env.VUE_APP_API_URL;
        let res = await axios.post(apiUrl + "/administration/setApothekeDetails", this.apothekeDetails);

        if (res.status != 200) {
          console.log(res.status);  
        }
      } catch (err) {
        
        console.log(err.response);
      }
    },
  },
  computed: {
    isAdmin: function () {
      return this.$store.getters.getRole == "admin";
    },
    ...sync("user", ["loggedIn"]),
  },
};
</script>