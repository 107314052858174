<template>
<v-container>
  <v-form v-model="isFormValid">
  <material-card    
    color="primary" 
    icon="mdi-lock-reset"    
    title="Neues Passwort"
      class="mb-5 pb-5"
      >
      <v-row>
        <v-col cols="3" class="ml-5">          
          <v-text-field
            v-model="passwordold"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"            
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Aktuelles Passwort"
            :rules="[rules.required]"
            counter
            @click:append="show1 = !show1"
            required
          ></v-text-field>
        </v-col>        
      </v-row>  
      <v-row>
        <v-col cols="3" class="ml-5">          
          <v-text-field
            v-model="passwordnew1"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"            
            :type="show2 ? 'text' : 'password'"
            name="input-10-1"
            label="Neues Passwort"
            :rules="[rules.required, rules.min]"
            counter
            @click:append="show2 = !show2"
            required
          ></v-text-field>
        </v-col>        
      </v-row>     
      <v-row>
        <v-col cols="3" class="ml-5">          
          <v-text-field
            v-model="passwordnew2"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"            
            :type="show3 ? 'text' : 'password'"
            name="input-10-1"
            label="Neues Passwort wiederholen"
            :rules="[rules.required, rules.min, rules.emailMatch]"
            counter
            @click:append="show3 = !show3"
            required
          ></v-text-field>
        </v-col>        
      </v-row>      
      <v-row>
        <v-col cols="3" class="ml-5">     
          <v-btn color="primary" v-on:click="changePassword" :disabled="!isFormValid"> Passwort ändern </v-btn>
          <p class="text-h4 mt-5" v-if="passwordIncorrect">Passwort nicht korrekt</p>
          <p class="text-h4 mt-5" v-if="passwordChanged">Passwort geändert</p>
        </v-col>
      </v-row>     
  </material-card>
</v-form>
</v-container>
</template>

<script>
  import axios from "axios";

  export default {
    name: 'NewPassword',
    components: {
       
  },
  data() {
    return {
      isFormValid: false,
      passwordIncorrect: false,
      passwordChanged: false,
      show1: false,
      show2: false,
      show3: false,
      passwordold: null,   
      passwordnew1: null,
      passwordnew2: null,
        rules: {
          required: value => !!value || 'Erforderlich',
          min: v => (v && v.length >= 8) || 'Mindestens 8 Zeichen',
          emailMatch: () => (this.passwordnew1 == this.passwordnew2) || (`Die Passwörter sind nicht identisch`),
        },  
    }
  },
  methods: {    
    async changePassword() {
      const apiUrl = process.env.VUE_APP_API_URL;

    try {
      var dto = {
        oldPassword: this.passwordold,
        newPassword: this.passwordnew1
      };

      const response = await axios.post(apiUrl + "/administration/changepassword", dto);
      
      if (response) { 
        if(response.data === true)    
        {
          this.passwordIncorrect = false;
          this.passwordChanged = true;
        }
        else {
          this.passwordIncorrect = true;
          this.passwordChanged = false;
        }
      }
    } catch (e) {
      this.errors.push(e);
    }
    }
  }, 
  async created() {
    this.$store.commit('setPageTitle', "Passwort ändern");
  }  
}

</script>