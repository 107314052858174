<template>
  <v-container id="user-login-view" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <material-card color="primary" icon="mdi-account-outline">
          <template #title>{{ $t('login') }}</template>
          <form @submit.prevent="loginUser">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field 
                    label="Benutzername"
                    v-model="login.email"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="login.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    label="Passwort"
                    counter
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-left">
                  <v-btn color="primary" min-width="150" type="submit" :loading="loginWait">
                    Login
                  </v-btn>
                  <v-btn plain to="/resetPassword">Passwort vergessen</v-btn>
                </v-col>              
              </v-row>
              <v-row v-if="loginError">
                <v-col cols="12" class="text-left">
                  Benutztername oder Passwort nicht korrekt
                </v-col>
              </v-row>
            </v-container>
          </form>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "UserLoginView",
  data() {
    return {
      login: {
        email: "",
        password: "",
      },
      showPassword: false,
      loginError: false,
      loginWait: false,
    };
  },
  methods: {
    async loginUser () {
      this.loginWait = true;
      
      try {
        const apiUrl = process.env.VUE_APP_API_URL;

        let response = await axios.post(apiUrl + "/api/authenticate", this.login);
        this.$store.commit('changeToken', response.data.token);
        this.$store.commit('changeLoggedIn', true);        

        if (response.data.changePassword) {
          this.$router.push("/firstPassword");
        }
        else {
          this.$router.push("/dashboard");
        }

      } catch (err) {
        this.loginError = true;
        console.log(err.response);
      }

      this.loginWait = false;
    },
  },
  async created() {
    this.$store.commit('setPageTitle', "Login");
  }
};
</script>
