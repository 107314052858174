<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-cash-register"    
    title="Entwicklung abgegebene Packungen "
      class="mb-5 pb-5"
      >
      <v-row>
        <v-col cols="12">          
          <canvas class="pl-5 pr-5 mb-10" id="lieferfaehigkeitEntwicklungGesamtChart"></canvas>    
        </v-col>    
      </v-row>   
      <v-row>
        <v-col cols="12">          
          <v-data-table          
            :headers="headersTable"
            :items="tableValues"
            :items-per-page="10"
            class="ml-5 mr-5 mb-5"    
            hide-default-footer
            >
            <template v-for="h in headersTable2" v-slot:[`header.${h.value}`]="{ headersTable2 }">
              <v-tooltip bottom :key="h.text">
                <template v-slot:activator="{ on }">
                  <span v-html="h.text.replace(' ', '<br />')" v-on="on"></span>
                </template>
                <span>{{h.text}}</span>
              </v-tooltip>
            </template>

          <template
              v-for="header in headersTable.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row> 
      </material-card>
      <material-card>
      <v-row>
        <v-col cols="12">          
          <canvas class="pl-5 pr-5 mb-10" id="lieferfaehigkeitEntwicklungNachAbgabeChart"></canvas>    
        </v-col>    
      </v-row>    
      <v-row>
        <v-col cols="12">          
          <v-data-table          
            :headers="headersTable2"
            :items="tableValues2"
            :items-per-page="10"
            class="ml-5 mr-5 mb-5"    
            hide-default-footer
  >
            
            <template v-for="h in headersTable2" v-slot:[`header.${h.value}`]="{ headersTable2 }">
              <v-tooltip bottom :key="h.text">
                <template v-slot:activator="{ on }">
                  <span v-html="h.text.replace(' ', '<br />')" v-on="on"></span>
                </template>
                <span>{{h.text}}</span>
              </v-tooltip>
            </template>
  
            <template
              v-for="header in headersTable2.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row> 
  </material-card>

</v-container>
</template>

<script>
  import axios from "axios";
  import Chart from 'chart.js/auto'
  
  Chart.register();

  export default {
    name: 'AbgegebenePackungenEntwicklung',
    components: {
       
  },
  data() {
    return {
      chartGesamt: null,
      chartAbgabe: null,
      configGesamt: {
        type: 'line',
        data: {
          labels: [],
          datasets: [
            {
              label: 'Gesamt',
              data: [],
              borderColor: "#31807A",
              fill: false,
              tension: 0.4,
              datalabels: {
                display: false
              }
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Abgegebene Packungen',
              font: {
                size: 22
              }
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: false,
                text: 'Value'
              },  
              grace: "20%",
              //suggestedMin: -10,            
              //suggestedMax: 100,
            }
          }
        },
      },    
      configAbgabe: {
        type: 'line',
        data: {
          labels: [],
          datasets: [
            {
              label: 'RX',
              data: [],
              borderColor: "#31807A",
              fill: false,
              tension: 0.4,
              datalabels: {
                display: false
              }
            }, {
              label: 'OTC',
              data: [],
              borderColor: "#2C3F60",
              fill: false,
              tension: 0.4,
              datalabels: {
                display: false
              }
            }, {
              label: 'FW',
              data: [],
              borderColor: "#34AC8B",
              fill: false,
              tension: 0.4,
              datalabels: {
                display: false
              }
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Abgegebene Packungen nach Abgabe',
              font: {
                size: 22
              }
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: false,
                text: 'Value'
              },       
              grace: "20%",       
              //suggestedMax: 100,
            }
          }
        },
      },
      headersTable: [
        {
          text: "Spalte1",
          sortable: false,
          value: 'spalte1',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%",
        },
        {
          text: "Spalte2",
          sortable: false,
          value: 'spalte2',
          align: 'right',
          formatter: this.formatNumber,
          width: "8%",
        },
        {
          text: "Spalte3",
          sortable: false,
          value: 'spalte3',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%",
        },
        {
          text: "Spalte4",
          sortable: false,
          value: 'spalte4',
          align: 'right',
          formatter: this.formatNumber,
          width: "8%",
        },
        {
          text: "Spalte5",
          sortable: false,
          value: 'spalte5',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%",
        },
        {
          text: "Spalte6",
          sortable: false,
          value: 'spalte6',
          align: 'right',
          formatter: this.formatNumber,
          width: "8%",
        },
        {
          text: "Spalte7",
          sortable: false,
          value: 'spalte7',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%",
        },
        {
          text: "Spalte8",
          sortable: false,
          value: 'spalte8',
          align: 'right',
          formatter: this.formatNumber,
          width: "8%",
        },
        {
          text: "Spalte9",
          sortable: false,
          value: 'spalte9',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%",
        },
        {
          text: "Spalte10",
          sortable: false,
          value: 'spalte10',
          align: 'right',
          formatter: this.formatNumber,
          width: "8%",
        },
        {
          text: "Spalte11",
          sortable: false,
          value: 'spalte11',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%",
        },
        {
          text: "Spalte12",
          sortable: false,
          value: 'spalte12',
          align: 'right',
          formatter: this.formatNumber,
          width: "8%",
        },
        {
          text: "Spalte13",
          sortable: false,
          value: 'spalte13',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%",
        }
      ],
      tableValues: [],
      headersTable2: [
        {
          text: "Abgabe",
          sortable: false,
          value: 'spalte0',
          width: "9%",
        },
        {
          text: "Spalte1",
          sortable: false,
          value: 'spalte1',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        },
        {
          text: "Spalte2",
          sortable: false,
          value: 'spalte2',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        },
        {
          text: "Spalte3",
          sortable: false,
          value: 'spalte3',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        },
        {
          text: "Spalte4",
          sortable: false,
          value: 'spalte4',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        },
        {
          text: "Spalte5",
          sortable: false,
          value: 'spalte5',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        },
        {
          text: "Spalte6",
          sortable: false,
          value: 'spalte6',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        },
        {
          text: "Spalte7",
          sortable: false,
          value: 'spalte7',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        },
        {
          text: "Spalte8",
          sortable: false,
          value: 'spalte8',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        },
        {
          text: "Spalte9",
          sortable: false,
          value: 'spalte9',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        },
        {
          text: "Spalte10",
          sortable: false,
          value: 'spalte10',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        },
        {
          text: "Spalte11",
          sortable: false,
          value: 'spalte11',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        },
        {
          text: "Spalte12",
          sortable: false,
          value: 'spalte12',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        },
        {
          text: "Spalte13",
          sortable: false,
          value: 'spalte13',
          align: 'right',
          width: "7%",
          formatter: this.formatNumber
        }
      ],
      tableValues2: [],
    }
  },
  methods: {
    toFixedIfNecessary( value, dp ){
      if (value >= 1000) {
        return value.toLocaleString();
      }
      else {
        return +parseFloat(value).toFixed( dp );
      }
    },
    formatNumber(value) {
        return value.toLocaleString();      
    },
    prozentwert(val, index) {

      var sum = this.configAbgabe.data.datasets[0].data[index] + 
                this.configAbgabe.data.datasets[1].data[index] + 
                this.configAbgabe.data.datasets[2].data[index];

      var proRx = Math.round(100 / sum * this.configAbgabe.data.datasets[0].data[index]).toFixed(0);
      var proOtc = Math.round(100 / sum * this.configAbgabe.data.datasets[1].data[index]).toFixed(0);
      var proFw = 100 - proRx - proOtc;


      if (val === 'rx') {
        return " (" + proRx + "%)";
      }
      else if (val === 'otc'){
        return " (" + proOtc + "%)";
      }
      else if (val === 'fw'){
        return " (" + proFw + "%)";
      }
      
      return '';
    }
  },
  mounted() {
    var ctxGesamt = document.getElementById('lieferfaehigkeitEntwicklungGesamtChart');
    ctxGesamt.height = 300;
    this.chartGesamt = new Chart(ctxGesamt, this.configGesamt);

    var ctxAbgabe = document.getElementById('lieferfaehigkeitEntwicklungNachAbgabeChart');
    ctxAbgabe.height = 300;
    this.chartAbgabe = new Chart(ctxAbgabe, this.configAbgabe);
  },
  async created() {

    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/report/abgegebenePackungenentwicklung/" + apoId);    
      
      if (response) {     

          this.configGesamt.data.labels = response.data.labels;         
          this.configGesamt.data.datasets[0].data = response.data.gesamt;

          for(let i=0; i < response.data.labels.length; i++)
          {           
            this.headersTable[i].text = response.data.labels[i];
          }

          this.tableValues.push(
              {
                spalte1: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[0]),
                spalte2: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[1]),
                spalte3: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[2]),
                spalte4: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[3]),
                spalte5: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[4]),
                spalte6: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[5]),
                spalte7: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[6]),
                spalte8: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[7]),
                spalte9: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[8]),
                spalte10: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[9]),
                spalte11: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[10]),
                spalte12: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[11]),
                spalte13: this.toFixedIfNecessary(this.configGesamt.data.datasets[0].data[12]),
              }
            );

          this.configAbgabe.data.labels = response.data.labels;   
          this.configAbgabe.data.datasets[0].data = response.data.rx;
          this.configAbgabe.data.datasets[1].data = response.data.otc;
          this.configAbgabe.data.datasets[2].data = response.data.fw;

          for(let i=0; i < response.data.labels.length; i++)
          {           
            this.headersTable2[i+1].text = response.data.labels[i];
          }

          this.tableValues2.push(
              {
                spalte0: "RX",
                spalte1: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[0]) + this.prozentwert('rx', 0),
                spalte2: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[1]) + this.prozentwert('rx', 1),
                spalte3: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[2]) + this.prozentwert('rx', 2),
                spalte4: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[3]) + this.prozentwert('rx', 3),
                spalte5: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[4]) + this.prozentwert('rx', 4),
                spalte6: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[5]) + this.prozentwert('rx', 5),
                spalte7: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[6]) + this.prozentwert('rx', 6),
                spalte8: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[7]) + this.prozentwert('rx', 7),
                spalte9: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[8]) + this.prozentwert('rx', 8),
                spalte10: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[9]) + this.prozentwert('rx', 9),
                spalte11: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[10]) + this.prozentwert('rx', 10),
                spalte12: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[11]) + this.prozentwert('rx', 11),
                spalte13: this.toFixedIfNecessary(this.configAbgabe.data.datasets[0].data[12]) + this.prozentwert('rx', 12),
              }
            );

          this.tableValues2.push(
              {
                spalte0: "OTC",
                spalte1: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[0]) + this.prozentwert('otc', 0),
                spalte2: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[1]) + this.prozentwert('otc', 1),
                spalte3: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[2]) + this.prozentwert('otc', 2),
                spalte4: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[3]) + this.prozentwert('otc', 3),
                spalte5: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[4]) + this.prozentwert('otc', 4),
                spalte6: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[5]) + this.prozentwert('otc', 5),
                spalte7: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[6]) + this.prozentwert('otc', 6),
                spalte8: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[7]) + this.prozentwert('otc', 7),
                spalte9: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[8]) + this.prozentwert('otc', 8),
                spalte10: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[9]) + this.prozentwert('otc', 9),
                spalte11: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[10]) + this.prozentwert('otc', 10),
                spalte12: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[11]) + this.prozentwert('otc', 11),
                spalte13: this.toFixedIfNecessary(this.configAbgabe.data.datasets[1].data[12]) + this.prozentwert('otc', 12),
              }
            );

          this.tableValues2.push(
              {
                spalte0: "FW",
                spalte1: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[0]) + this.prozentwert('fw', 0),
                spalte2: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[1]) + this.prozentwert('fw', 1),
                spalte3: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[2]) + this.prozentwert('fw', 2),
                spalte4: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[3]) + this.prozentwert('fw', 3),
                spalte5: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[4]) + this.prozentwert('fw', 4),
                spalte6: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[5]) + this.prozentwert('fw', 5),
                spalte7: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[6]) + this.prozentwert('fw', 6),
                spalte8: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[7]) + this.prozentwert('fw', 7),
                spalte9: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[8]) + this.prozentwert('fw', 8),
                spalte10: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[9]) + this.prozentwert('fw', 9),
                spalte11: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[10]) + this.prozentwert('fw', 10),
                spalte12: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[11]) + this.prozentwert('fw', 11),
                spalte13: this.toFixedIfNecessary(this.configAbgabe.data.datasets[2].data[12]) + this.prozentwert('fw', 12),
              }
            );

          this.chartGesamt.update();
          this.chartAbgabe.update();
      }        
    } catch (e) {
      //this.errors.push(e);
    }
  }
}

</script>