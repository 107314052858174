<template>
  <v-container>
    <v-row
      class="pa-4"
      justify="space-between"
    >
      <v-col cols="5">
        <v-treeview
          :active.sync="active"
          :items="parameter"          
          activatable
        >
          
        </v-treeview>
      </v-col>
      <v-divider vertical></v-divider>

      <v-col
        class="d-flex text-center"
      >
        <v-scroll-y-transition mode="out-in">
          <div            
            class="text-h6 grey--text text--lighten-1 font-weight-light"
            style="align-self: center;"
          >
            Set a value
            {{ this.selected?.name }} 
            <v-text-field
              v-model="selected.value"
              label="Wert"
            ></v-text-field>
          </div>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ApothekeEinstellungen",
  props: {
    value: {
      type: Object,
    },
    filter: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data: () => ({
    active: [],
    parameter: [{
      id: 1,
      name: "para1",
      value: 1.5,
      children: [
        {
          id: 6,
          name: "para1.1",
          value: 1.7,
          children: []
        },
        {
          id: 3,
          name: "para1.2",
          value: 1.3,
          children: []
        }
        ]
      }]
   }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },    
    selected () {        

        const find = (array = [], id) => {
          for (const item of array) {
            const result = item.id === id ? item : find(item.children, id);
            if(result) return result;
          }
        };

        if (!this.active.length) return undefined

        const id = this.active[0];
        
        const result = find(this.parameter, id);
        return result;

      },
      
  },
  methods: {
    

  }
};
</script>