<template>
  <v-container>
    <material-card    
      color="primary" 
      icon="mdi-cog-outline"    
      title="Mengensteuerung"
        class="mb-5 pb-5"
        >
        <v-card    
          class="mx-auto ml-3 mr-3 mt-3"
        >        
          <v-card-text>
            <p class="text-h3">Mindestbestand</p>
            <v-row>
              <v-col cols="12">
                <ApothekeMengensteuerungEinstellungen bezeichnung="Wiederbeschaffungszeit" :model="wiederbeschaffungszeit">
                </ApothekeMengensteuerungEinstellungen>
              </v-col> 
            </v-row>
            <v-row>
              <v-col cols="12">
                <ApothekeMengensteuerungEinstellungen bezeichnung="Sicherheitslevel" :model="sicherheitslevel" :prozentual="true">
                </ApothekeMengensteuerungEinstellungen>
              </v-col> 
            </v-row>
          </v-card-text>
        </v-card>
        <v-card    
          class="mx-auto ml-3 mr-3 mt-3"
        >        
          <v-card-text>
            <p class="text-h3">Bevorratung</p>
            <v-row>
              <v-col cols="12">
                <ApothekeMengensteuerungEinstellungen bezeichnung="Maximale Bevorratungszeit" :model="maxBevorratungszeit" :detailiert="false">
                </ApothekeMengensteuerungEinstellungen>
              </v-col> 
            </v-row>            
          </v-card-text>
        </v-card>
    </material-card>
  </v-container>
</template>

<script>
  import axios from "axios";
  import ApothekeMengensteuerungEinstellungen from '@/components/ApothekeMengensteuerungEinstellungen.vue';
  
  export default {
    name: 'MengensteuerungView',
    components: {
      ApothekeMengensteuerungEinstellungen
  },
  data() {
    return {
      wiederbeschaffungszeit: {
        allgemein: 0,
        rx: {
          wert: 0,
          schnell: 0,
          schnellGuenstig: 0
        },
        sw: {
          wert: 0,
          schnell: 0,
          schnellGuenstig: 0
        },
        fw: {
          wert: 0,
          schnell: 0,
          schnellGuenstig: 0
        }
      },
      sicherheitslevel: {
        allgemein: 0,
        rx: {
          wert: 0,
          schnell: 0,
          schnellGuenstig: 0
        },
        sw: {
          wert: 0,
          schnell: 0,
          schnellGuenstig: 0
        },
        fw: {
          wert: 0,
          schnell: 0,
          schnellGuenstig: 0
        }
      },
      maxBevorratungszeit: {
        allgemein: 0,
        rx: {
          wert: 0,
          schnell: 0,
          schnellGuenstig: 0
        },
        sw: {
          wert: 0,
          schnell: 0,
          schnellGuenstig: 0
        },
        fw: {
          wert: 0,
          schnell: 0,
          schnellGuenstig: 0
        }
      },
    }
  },
  methods: {
    
  },
  mounted() {
    
  },
  async created() {

    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/apotheken/mengensteuerung/" + apoId);    
      
      if (response) {     

          this.wiederbeschaffungszeit = response.data.wiederbeschaffungszeit;  
          this.sicherheitslevel = response.data.sicherheitslevel;  
          this.maxBevorratungszeit = response.data.maxBevorratungszeit;  
      }   
              
    } catch (e) {
      this.errors.push(e);
    }
  },
}

</script>