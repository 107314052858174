<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        
          <v-text-field
            v-model="model.rechungsbetrag"
            label="monatliche Lizenzgebühr (netto)"
            prefix="€"
         ></v-text-field>
         <v-switch
              v-model="model.sepaMandat"
              label="SEPA Mandat vorhanden"
    ></v-switch>
    <v-text-field v-if="model.sepaMandat"
          v-model="model.nameZahlungsdienstleisters"
          label="Name des Zahlungsdienstleisters"
        ></v-text-field>
        <v-text-field v-if="model.sepaMandat"
          v-model="model.iban"
          label="IBAN"
        ></v-text-field>
        <v-text-field v-if="model.sepaMandat"
          v-model="model.bic"
          label="BIC"
        ></v-text-field>

        <table>
          <tr>
            <td>
        <v-layout row wrap>
        <v-menu
      v-model="fromDateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
    <template v-slot:activator="{ on }">
        <v-text-field              
            :value="fromDateDisp"
            label="Rechungsdatum"
            readonly
            prepend-icon="mdi-calendar"
            v-on="on"
        ></v-text-field>
      </template>
        <v-date-picker
          locale="de-in"
          :min="minDate"
          v-model="rechnungsdatum"
          no-title
          @input="fromDateMenu = false"
        ></v-date-picker>
      </v-menu>
    </v-layout>
    </td>
    <td>&nbsp;</td>
    <td>
    <v-select
          :items="rechnungstypen"
          v-model="rechnungstyp"
          label="Typ"
          dense
        ></v-select>
      </td>
    <td>
        <v-btn      
      @click="einrichtungsRechung()"
      :loading="loginWait"
      class="mx-2"      
      color="primary"
      fab
      dark
      small
    >
      <v-icon>
          mdi-file-document-plus
      </v-icon>
    </v-btn></td>
          </tr>
        </table>

      </v-col> 
      <v-col cols="9">
        <v-data-table          
            :headers="headersTable"
            :items="tableValues"
            :items-per-page="10"
            class="ml-5 mr-5 mb-5"    
            hide-default-footer
          >
        </v-data-table>
      </v-col>      
    </v-row>
   
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "ApothekeRechnung",
  props: {
    value: {
      type: Object,
    },
    filter: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data: () => ({
        rechnungsdatum: null,
        fromDateMenu: false,    
        minDate: "2022-06-01",
        rechnungErstellen: { 
          id: null, 
          rechungsdatum: null 
        },
        loginWait: false,
        rechnungstypen: ["Lizenz", "Einrichtung"],
        rechnungstyp: null,
        headersTable: [
          {
          text: "Datum",
          sortable: true,
          value: 'datum',
        },
        {
          text: "Betrag",
          sortable: true,
          value: 'betrag',
        }
      ],
      tableValues: [
        {
          betrag: 120,
          datum: '2022-10-29'
        }
      ],
   }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    fromDateDisp() {
        if(this.rechnungsdatum) {
          const datum = new Date(Date.parse(this.rechnungsdatum));
          return datum.toLocaleDateString('de-DE');
        }

        return null;
    },
  },
  methods: {
    async einrichtungsRechung() {
      this.loginWait = true;

      this.rechnungErstellen.id = this.model.id;
      this.rechnungErstellen.rechungsdatum = this.rechnungsdatum;

      try {
        const apiUrl = process.env.VUE_APP_API_URL;

        if (this.rechnungstyp == "Einrichtung") {
          let res = await axios.post(apiUrl + "/administration/createRechnungEinrichtung", this.rechnungErstellen);
          if (res.data === true) {
            this.loginWait = false;
          }
        }   
        else if (this.rechnungstyp == "Lizenz") {
          this.loginWait = false;
        }     
        else {
          this.loginWait = false;
        }
      } catch (err) {        
        console.log(err.response);        
      }
    }
  }
};
</script>