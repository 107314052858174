<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-account"    
    title="Benutzer"
      class="mb-5 pb-5"
      >
      <v-row>
        <v-col cols="4" class="ml-5">          
        
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      </v-col>
      <v-col cols="4">
      <v-btn
        small
        fab
        dark
        color="primary"
        v-on:click="newUser"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
    
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pl-5 pr-15">      
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
    >
    <template v-slot:item.edit="{ item }">
          <v-icon @click="editItem(item)" class="mr-4">
            mdi-pencil
          </v-icon>
          <v-icon @click="showApothekeDialog(item)" class="mr-4">
            mdi-home-group-plus
          </v-icon>
        </template>
  </v-data-table>

  <v-dialog
        v-model="dialogNewUser"
        max-width="700px"
      >
        <v-card>
          <v-card-title>
            <span>Benutzer</span>         
          </v-card-title>

          <v-text-field
              v-model="editedItem.email"
              label="E-Mail"
              class="ml-6 mr-6 mt-6"
              :disabled="editedIndex != -1"
            ></v-text-field>

            <v-select
              v-model="editedItem.anrede"
              :items="anrede"
              class="ml-6 mr-6 mt-3"
              label="Anrede"
              dense
            ></v-select>
            
            <v-text-field
                v-model="editedItem.vorname"
                label="Vorname"
                class="ml-6 mr-6"
              ></v-text-field>
            
          <v-text-field
              v-model="editedItem.name"
              label="Name"
              class="ml-6 mr-6"
            ></v-text-field>

        <v-select
              v-model="editedItem.role"
              :items="roles"
              class="ml-6 mr-6 mt-3"
              label="Berechtigungslevel"
              dense
        ></v-select>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="editUser"
            >
              OK
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="dialogNewUser = false"
            >
              Abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogApotheken"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Apothekenzuordnung von {{ this.editedItem.vorname }} {{ this.editedItem.name }} ({{ this.editedItem.email }})
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchApotheke"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>        
          </v-card-title>

          <v-data-table
            v-model="selectedApotheken"
            :headers="headersApotheken"
            :items="apotheken"
            :search="searchApotheke"
            item-key="id"
            class="ml-5 mr-5"
            show-select
          >            
          </v-data-table>
          

          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="editApotheke"
            >
              OK
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="dialogApotheken = false"
            >
              Abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
        </v-col>
      </v-row>     
  </material-card>
</v-container>
</template>

<script>
  
  import axios from "axios";

  export default {
    name: 'UsersAdministration',
    components: {
       
  },
  data() {
    return {
      dialogNewUser: false,  
      dialogApotheken: false,     
      search: '',
      searchApotheke: '',
      editedIndex: -1,
      editedItem: 
      {
        id: null,
        email: null,
        vorname: null,
        name: null,
        role: "",
        anrede: "",
      },
      roles: [
        { 
          value: "User",
          text: "Benutzer"
        },
        { 
          value: "Admin",
          text: "Administrator"
        },
        { 
          value: "Supervisor",
          text: "Betreuer"
        }
      ],
      anrede: [
        "Herr",
        "Frau",
      ],
        headers: [          
          {
            text: 'E-Mail',
            align: 'start',
            sortable: false,
            value: 'email',
          },
          { text: 'Name', value: 'name' },
          { text: 'Vorname', value: 'vorname' },
          { text: 'Berechtigung', value: 'role' },
          { text: "", value: "edit", sortable: false, align: "end" },
        ],
        users: [],
        selectedApotheken: [],
        headersApotheken: [
        {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'IDF-Nummer', value: 'idf' },
          { text: 'Ort', value: 'ort' }
        ],
        apotheken: []
    }    
  },
  methods: {    
    openItem() {
      this.dialogNewUser = true;
    },
    newUser() {
      this.editedIndex = -1;
      this.editedItem.id = null;
      this.editedItem.email = null;
      this.editedItem.anrede = null;
      this.editedItem.vorname = null;
      this.editedItem.name = null;
      this.editedItem.role = "";
      this.dialogNewUser = true;
    },
    async editUser() {
      const apiUrl = process.env.VUE_APP_API_URL;

      if (this.editedIndex === -1)
      {
        try {      
          
          var dto = {
            email: this.editedItem.email,
            name: this.editedItem.name,
            vorname: this.editedItem.vorname,
            anrede: this.editedItem.anrede,
            role: this.editedItem.role
          };

          await axios.post(apiUrl + "/administration/createuser", dto);  
          
        } catch (e) {
          this.errors.push(e);
        }
      }
      else {
        try {      
          
          var dto1 = {
            id: this.editedItem.id,
            email: this.editedItem.email,
            anrede: this.editedItem.anrede,
            name: this.editedItem.name,
            vorname: this.editedItem.vorname,
            role: this.editedItem.role
          };
          await axios.post(apiUrl + "/administration/edituser", dto1);  
          
        } catch (e) {
          this.errors.push(e);
        }
      }
      this.dialogNewUser = false;

      await this.loadData();
    },
    editItem (item) {
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogNewUser = true
      },
    async showApothekeDialog (item) {

      this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)

      try {      
        const apiUrl = process.env.VUE_APP_API_URL;

        var dto = { userId: -1 };

        const response = await axios.post(apiUrl + "/administration/getUserApotheken", dto);  

        if(response) {
          this.apotheken = response.data;

          dto = { userId: this.editedItem.id };
          const responseApo = await axios.post(apiUrl + "/administration/getUserApotheken", dto);  
          if (responseApo) {
            this.selectedApotheken = responseApo.data;
          }
        }

      } catch (e) {
        this.errors.push(e);
      }

      this.dialogApotheken = true;

    },
    async editApotheke()
    {
      var dto = {
        userId: this.editedItem.id,
        apotheken: this.selectedApotheken
      };

      try {      
        const apiUrl = process.env.VUE_APP_API_URL;
        await axios.post(apiUrl + "/administration/setUserApotheken", dto);  

      } catch (e) {
        this.errors.push(e);
      }

      this.editApothekeClose();
    },
    editApothekeClose()
    {
      this.dialogApotheken = false;
      this.reset();
    },
    async loadData()
    {
      try {      
        const apiUrl = process.env.VUE_APP_API_URL;
        const response = await axios.get(apiUrl + "/administration/getusers");  

        if(response) {
          this.users = response.data;
        }

      } catch (e) {
        this.errors.push(e);
      }
    },
    reset() {
      this.editedIndex = null;
      this.editedItem.id = null;
      this.editedItem.email = null;
      this.editedItem.anrede = null;
      this.editedItem.vorname = null;
      this.editedItem.name = null;
      this.editedItem.role = null;
    }
  },
  async created() {
    this.$store.commit('setPageTitle', "Benutzerverwaltung");
    
    this.loadData();
  }
}

</script>