<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-chart-bar-stacked"    
    title="Lagerumschlag"
      class="mb-5 pb-5"
      >
      <v-row>
        <v-col cols="12">          
          <canvas class="pl-5 pr-5 mb-10" id="lieferfaehigkeitEntwicklungGesamtChart"></canvas>    
        </v-col>    
      </v-row>   
      <v-row>
        <v-col cols="12">          
          <v-data-table          
            :headers="headersTable"
            :items="tableValues"
            :items-per-page="10"
            class="ml-5 mr-5 mb-5"    
            hide-default-footer
          >
            <template v-for="h in headersTable" v-slot:[`header.${h.value}`]="{ headersTable }">
              <v-tooltip bottom :key="h.text">
                <template v-slot:activator="{ on }">
                  <span v-html="h.text.replace(' ', '<br />')" v-on="on"></span>
                </template>
                <span>{{h.text}}</span>
              </v-tooltip>
            </template>
          <template
              v-for="header in headersTable.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row> 
      </material-card>
      <material-card>
      <v-row>
        <v-col cols="12">          
          <canvas class="pl-5 pr-5 mb-10" id="lieferfaehigkeitEntwicklungNachAbgabeChart"></canvas>    
        </v-col>    
      </v-row>    
      <v-row>
        <v-col cols="12">          
          <v-data-table          
            :headers="headersTable2"
            :items="tableValues2"
            :items-per-page="10"
            class="ml-5 mr-5 mb-5"    
            hide-default-footer>      
            <template v-for="h in headersTable2" v-slot:[`header.${h.value}`]="{ headersTable2 }">
              <v-tooltip bottom :key="h.text">
                <template v-slot:activator="{ on }">
                  <span v-html="h.text.replace(' ', '<br />')" v-on="on"></span>
                </template>
                <span>{{h.text}}</span>
              </v-tooltip>
            </template>  
          <template
              v-for="header in headersTable2.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row> 
  </material-card>

</v-container>
</template>

<script>
  import axios from "axios";
  import Chart from 'chart.js/auto'
  
  Chart.register();

  export default {
    name: 'LagerumschlagEntwicklung',
    components: {
       
  },
  data() {
    return {
      chartGesamt: null,
      chartAbgabe: null,
      configGesamt: {
        type: 'line',
        data: {
          labels: [],
          datasets: [
            {
              label: 'Gesamt',
              data: [],
              borderColor: "#31807A",
              fill: false,
              tension: 0.4,
              datalabels: {
                display: false
              }
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Lagerumschlag in Tagen gesamt',
              font: {
                size: 22
              }
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: false,
                text: 'Value'
              },
              grace: "20%", 
              min: 0,
              //suggestedMin: 0,              
              //suggestedMax: 100,
            }
          }
        },
      },    
      configAbgabe: {
        type: 'line',
        data: {
          labels: [],
          datasets: [
            {
              label: 'RX',
              data: [],
              borderColor: "#31807A",
              fill: false,
              tension: 0.4,
              datalabels: {
                display: false
              }
            }, {
              label: 'OTC',
              data: [],
              borderColor: "#2C3F60",
              fill: false,
              tension: 0.4,
              datalabels: {
                display: false
              }
            }, {
              label: 'FW',
              data: [],
              borderColor: "#34AC8B",
              fill: false,
              tension: 0.4,
              datalabels: {
                display: false
              }
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Lagerumschlag in Tagen nach Abgaberegel',
              font: {
                size: 22
              }
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: false,
                text: 'Value'
              }, 
              grace: "20%",   
              min: 0,
              //suggestedMax: 100,
            }
          }
        },
      },
      headersTable: [
        {
          text: "Spalte1",
          sortable: false,
          value: 'monatMinus13',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte2",
          sortable: false,
          value: 'monatMinus12',
          align: 'right',
          formatter: this.formatNumber,
          width: "8%"
        },
        {
          text: "Spalte3",
          sortable: false,
          value: 'monatMinus11',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte4",
          sortable: false,
          value: 'monatMinus10',
          align: 'right',
          formatter: this.formatNumber,
          width: "8%"
        },
        {
          text: "Spalte5",
          sortable: false,
          value: 'monatMinus9',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte6",
          sortable: false,
          value: 'monatMinus8',
          align: 'right',
          formatter: this.formatNumber,
          width: "8%"
        },
        {
          text: "Spalte7",
          sortable: false,
          value: 'monatMinus7',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte8",
          sortable: false,
          value: 'monatMinus6',
          align: 'right',
          formatter: this.formatNumber,
          width: "8%"
        },
        {
          text: "Spalte9",
          sortable: false,
          value: 'monatMinus5',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte10",
          sortable: false,
          value: 'monatMinus4',
          align: 'right',
          formatter: this.formatNumber,
          width: "8%"
        },
        {
          text: "Spalte11",
          sortable: false,
          value: 'monatMinus3',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte12",
          sortable: false,
          value: 'monatMinus2',
          align: 'right',
          formatter: this.formatNumber,
          width: "8%"
        },
        {
          text: "Spalte13",
          sortable: false,
          value: 'monatMinus1',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        }
      ],
      tableValues: [],
      headersTable2: [
        {
          text: "Abgabe",
          sortable: false,
          value: 'abgaberegel',
          width: "9%"
        },
        {
          text: "Spalte1",
          sortable: false,
          value: 'monatMinus13',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte2",
          sortable: false,
          value: 'monatMinus12',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte3",
          sortable: false,
          value: 'monatMinus11',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte4",
          sortable: false,
          value: 'monatMinus10',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte5",
          sortable: false,
          value: 'monatMinus9',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte6",
          sortable: false,
          value: 'monatMinus8',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte7",
          sortable: false,
          value: 'monatMinus7',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte8",
          sortable: false,
          value: 'monatMinus6',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte9",
          sortable: false,
          value: 'monatMinus5',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte10",
          sortable: false,
          value: 'monatMinus4',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte11",
          sortable: false,
          value: 'monatMinus3',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte12",
          sortable: false,
          value: 'monatMinus2',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        },
        {
          text: "Spalte13",
          sortable: false,
          value: 'monatMinus1',
          align: 'right',
          formatter: this.formatNumber,
          width: "7%"
        }
      ],
      tableValues2: [],
    }
  },
  methods: {
    toFixedIfNecessary( value, dp ){
      if (value >= 1000) {
        return value.toLocaleString();
      }
      else {
        return +parseFloat(value).toFixed( dp );
      }
    },
    formatNumber(value) {
      // Just an simple example, you can do anything here
      return value.toFixed(2);
    },
  },
  mounted() {
    var ctxGesamt = document.getElementById('lieferfaehigkeitEntwicklungGesamtChart');
    ctxGesamt.height = 300;
    this.chartGesamt = new Chart(ctxGesamt, this.configGesamt);

    var ctxAbgabe = document.getElementById('lieferfaehigkeitEntwicklungNachAbgabeChart');
    ctxAbgabe.height = 300;
    this.chartAbgabe = new Chart(ctxAbgabe, this.configAbgabe);
  },
  async created() {

    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/report/lagerumschlagEntwicklung/" + apoId);    
      
      if (response) {     

          this.configGesamt.data.labels = response.data.labels;         
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus13, 1));         
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus12, 1));         
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus11, 1));         
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus10, 1));         
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus9, 1));         
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus8, 1));         
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus7, 1));         
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus6, 1));         
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus5, 1));        
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus4, 1));        
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus3, 1));        
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus2, 1));        
          this.configGesamt.data.datasets[0].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus1, 1));


          for(let i=0; i < response.data.labels.length; i++)
          {           
            this.headersTable[i].text = response.data.labels[i];
          }

          this.tableValues.push(
              {
                monatMinus13: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus13, 1),
                monatMinus12: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus12, 1),
                monatMinus11: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus11, 1),
                monatMinus10: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus10, 1),
                monatMinus9: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus9, 1),
                monatMinus8: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus8, 1),
                monatMinus7: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus7, 1),
                monatMinus6: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus6, 1),
                monatMinus5: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus5, 1),
                monatMinus4: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus4, 1),
                monatMinus3: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus3, 1),
                monatMinus2: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus2, 1),
                monatMinus1: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWert[0].monatMinus1, 1),
              }
            );

          this.configAbgabe.data.labels = response.data.labels;   
          for(let i=0; i < response.data.labels.length; i++)
          {           
            this.headersTable2[i+1].text = response.data.labels[i];
          }

          for(let i=0; i < 3; i++)
          { 
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus13, 1));         
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus12, 1));         
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus11, 1));         
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus10, 1));         
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus9, 1));         
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus8, 1));         
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus7, 1));         
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus6, 1));         
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus5, 1));        
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus4, 1));        
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus3, 1));        
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus2, 1));        
            this.configAbgabe.data.datasets[i].data.push(this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus1, 1));

            this.tableValues2.push(
              {
                abgaberegel: response.data.tabelleLagerumschlagWertNachAbgabe[i].abgaberegel,
                monatMinus13: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus13, 1),
                monatMinus12: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus12, 1),
                monatMinus11: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus11, 1),
                monatMinus10: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus10, 1),
                monatMinus9: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus9, 1),
                monatMinus8: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus8, 1),
                monatMinus7: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus7, 1),
                monatMinus6: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus6, 1),
                monatMinus5: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus5, 1),
                monatMinus4: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus4, 1),
                monatMinus3: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus3, 1),
                monatMinus2: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus2, 1),
                monatMinus1: this.toFixedIfNecessary(response.data.tabelleLagerumschlagWertNachAbgabe[i].monatMinus1, 1),
              }
            );
          }

          

          this.chartGesamt.update();
          this.chartAbgabe.update();
      }        
    } catch (e) {
      //this.errors.push(e);
    }
  }
}

</script>