<template>
  <v-container>    
        <p class="text-h4">{{ bezeichnung }}</p>
        <div class="ml-5">
        <v-row>
          <v-col cols="12" class="mt-2">
            Allgemein: {{ formatProzent(model.allgemein) }}
          </v-col>          
        </v-row>
        <v-row>
          <v-col cols="2" class="ml-7">
            RX: {{ formatProzent(model.rx.wert) }}
          </v-col>          
          <v-col v-if="detailiert" cols="2">
            RX <i>(schnell):</i> {{ formatProzent(model.rx.schnell) }}
          </v-col>
          <v-col v-if="detailiert" cols="2">
            RX <i>(schnell/günstig):</i> {{ formatProzent(model.rx.schnellGuenstig) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="ml-7">
            SW: {{ formatProzent(model.sw.wert) }}
          </v-col>          
          <v-col v-if="detailiert" cols="2">
            SW <i>(schnell):</i> {{ formatProzent(model.sw.schnell) }}
          </v-col>
          <v-col v-if="detailiert" cols="2">
            SW <i>(schnell/günstig):</i> {{ formatProzent(model.sw.schnellGuenstig) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="ml-7">
            FW: {{ formatProzent(model.fw.wert) }}
          </v-col>          
          <v-col v-if="detailiert" cols="2">
            FW <i>(schnell):</i> {{ formatProzent(model.fw.schnell) }}
          </v-col>
          <v-col v-if="detailiert" cols="2">
            FW <i>(schnell/günstig):</i> {{ formatProzent(model.fw.schnellGuenstig) }}
          </v-col>
        </v-row>
      </div>
  </v-container>
</template>

<script>

export default {
  name: "ApothekeListungsgrenzenEinstellungen",
  props: {
    bezeichnung: {
      type: String,
    },
    model: {
      type: Object,
    },
    prozentual: {
      type: Boolean,
      default: false,
    },
    detailiert: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    
   }),
  computed: {
    
  },
  methods: {
    formatProzent(wert)
    {
      if (this.prozentual) {
        let number = parseFloat(wert);
        return number.toLocaleString('de-DE') + '%';
      } 
      else 
      {
        return wert;
      }
    }
  }
};
</script>

<style scoped>
.v-text-field {
      width: 80px;
}
</style>