<template>
<v-container>
  <material-card    
    color="primary" 
    icon="mdi-human-dolly"    
    title="Entwicklung Wareneingang"
      class="mb-5 pb-5"
      >
      <v-row>
        <v-col cols="12">          
          <canvas class="pl-5 pr-5 mb-10" id="lieferfaehigkeitEntwicklungGesamtChart"></canvas>    
        </v-col>    
      </v-row>   
      <v-row>
        <v-col cols="12">          
          <v-data-table          
            :headers="headersTable"
            :items="tableValues"
            :items-per-page="10"
            class="ml-5 mr-5 mb-5"    
            hide-default-footer
  >
  <template v-for="h in headersTable" v-slot:[`header.${h.value}`]="{ headersTable }">
              <v-tooltip bottom :key="h.text">
                <template v-slot:activator="{ on }">
                  <span v-html="h.text.replace(' ', '<br />')" v-on="on"></span>
                </template>
                <span>{{h.text}}</span>
              </v-tooltip>
            </template>
          <template
              v-for="header in headersTable.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row> 
      </material-card>
      <material-card>
      <v-row>
        <v-col cols="12">          
          <canvas class="pl-5 pr-5 mb-10" id="lieferfaehigkeitEntwicklungNachAbgabeChart"></canvas>    
        </v-col>    
      </v-row>         
      <v-row>
        <v-col cols="12">          
          <v-data-table          
            :headers="headersTable"
            :items="tableValues2"
            :items-per-page="10"
            class="ml-5 mr-5 mb-5"    
            hide-default-footer
  >
  <template v-for="h in headersTable" v-slot:[`header.${h.value}`]="{ headersTable }">
              <v-tooltip bottom :key="h.text">
                <template v-slot:activator="{ on }">
                  <span v-html="h.text.replace(' ', '<br />')" v-on="on"></span>
                </template>
                <span>{{h.text}}</span>
              </v-tooltip>
            </template>  
          <template
              v-for="header in headersTable"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ value.toFixed(2) }}
            </template>
</v-data-table>
        </v-col>
      </v-row> 
  </material-card>

</v-container>
</template>

<script>
  import axios from "axios";
  import Chart from 'chart.js/auto'
  
  Chart.register();

  export default {
    name: 'WareneingangEntwicklungView',
    components: {
       
  },
  data() {
    return {
      chartWareneingangszeilen: null,
      chartLosgroesse: null,
      configWareneingangszeilen: {
        type: 'line',
        data: {
          labels: [],
          datasets: [
            {
              label: 'Wareneingangszeilen',
              data: [],
              borderColor: "#31807A",
              fill: false,
              tension: 0.4,
              datalabels: {
                display: false
              }
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Entwicklung Wareneingangszeilen',
              font: {
                size: 22
              }
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: false,
                text: 'Value'
              },
              grace: "20%",  
              //suggestedMin: -10,            
              //suggestedMax: 100,
            }
          }
        },
      },    
      configLosgroesse: {
        type: 'line',
        data: {
          labels: [],
          datasets: [
            {
              label: 'Einkaufslosgröße',
              data: [],
              borderColor: "#31807A",
              fill: false,
              tension: 0.4,
              datalabels: {
                display: false
              }
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Entwicklung Einkaufslosgröße (Großhandel)',
              font: {
                size: 22
              }
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: false,
                text: 'Value'
              }, 
              grace: "20%",             
              //suggestedMax: 100,
            }
          }
        },
      },
      
      headersTable: [
        {
          text: "Spalte1",
          sortable: false,
          value: 'spalte1',
          align: 'right',
          width: "7%"
        },
        {
          text: "Spalte2",
          sortable: false,
          value: 'spalte2',
          align: 'right',
          width: "8%"
        },
        {
          text: "Spalte3",
          sortable: false,
          value: 'spalte3',
          align: 'right',
          width: "7%"
        },
        {
          text: "Spalte4",
          sortable: false,
          value: 'spalte4',
          align: 'right',
          width: "8%"
        },
        {
          text: "Spalte5",
          sortable: false,
          value: 'spalte5',
          align: 'right',
          width: "7%"
        },
        {
          text: "Spalte6",
          sortable: false,
          value: 'spalte6',
          align: 'right',
          width: "8%"
        },
        {
          text: "Spalte7",
          sortable: false,
          value: 'spalte7',
          align: 'right',
          width: "7%"
        },
        {
          text: "Spalte8",
          sortable: false,
          value: 'spalte8',
          align: 'right',
          width: "8%"
        },
        {
          text: "Spalte9",
          sortable: false,
          value: 'spalte9',
          align: 'right',
          width: "7%"
        },
        {
          text: "Spalte10",
          sortable: false,
          value: 'spalte10',
          align: 'right',
          width: "8%"
        },
        {
          text: "Spalte11",
          sortable: false,
          value: 'spalte11',
          align: 'right',
          width: "7%"
        },
        {
          text: "Spalte12",
          sortable: false,
          value: 'spalte12',
          align: 'right',
          width: "8%"
        },
        {
          text: "Spalte13",
          sortable: false,
          value: 'spalte13',
          align: 'right',
          width: "7%"
        }
      ],
      tableValues: [],
      tableValues2: []
    }
  },
  methods: {
    toFixedIfNecessary( value, dp ){
      if (value >= 1000) {
        return value.toLocaleString();
      }
      else {
        return +parseFloat(value).toFixed( dp );
      }
    },
    formatNumber(value) {
      // Just an simple example, you can do anything here
      return value.toFixed(2);
    },
  },
  mounted() {
    var ctxWareneingangszeilen = document.getElementById('lieferfaehigkeitEntwicklungGesamtChart');
    ctxWareneingangszeilen.height = 300;
    this.chartWareneingangszeilen = new Chart(ctxWareneingangszeilen, this.configWareneingangszeilen);

    var ctxLosgroesse = document.getElementById('lieferfaehigkeitEntwicklungNachAbgabeChart');
    ctxLosgroesse.height = 300;
    this.chartLosgroesse = new Chart(ctxLosgroesse, this.configLosgroesse);
  },
  async created() {

    var apoId = parseInt(this.$route.params.id);

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/report/wareneingangEntwicklung/" + apoId);    
      
      if (response) {     

          this.configWareneingangszeilen.data.labels = response.data.labels;         
          for(let i=0; i < response.data.labels.length; i++)
          {           
            this.headersTable[i].text = response.data.labels[i];
          }
          
          this.configWareneingangszeilen.data.datasets[0].data = response.data.wareneingangszeilen;
          this.tableValues.push(
              {
                spalte1: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[0]),
                spalte2: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[1]),
                spalte3: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[2]),
                spalte4: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[3]),
                spalte5: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[4]),
                spalte6: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[5]),
                spalte7: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[6]),
                spalte8: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[7]),
                spalte9: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[8]),
                spalte10: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[9]),
                spalte11: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[10]),
                spalte12: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[11]),
                spalte13: this.toFixedIfNecessary(this.configWareneingangszeilen.data.datasets[0].data[12]),
              }
            );


          this.configLosgroesse.data.labels = response.data.labels;   
          this.configLosgroesse.data.datasets[0].data = response.data.einkaufslosgroesse;
          this.tableValues2.push(
              {
                spalte1: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[0],2),
                spalte2: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[1],2),
                spalte3: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[2],2),
                spalte4: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[3],2),
                spalte5: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[4],2),
                spalte6: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[5],2),
                spalte7: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[6],2),
                spalte8: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[7],2),
                spalte9: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[8],2),
                spalte10: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[9],2),
                spalte11: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[10],2),
                spalte12: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[11],2),
                spalte13: this.toFixedIfNecessary(this.configLosgroesse.data.datasets[0].data[12],2),
              }
            );

          this.chartWareneingangszeilen.update();
          this.chartLosgroesse.update();
      }        
    } catch (e) {
      this.errors.push(e);
    }
  }
}

</script>